// Core
import { take, fork, all } from 'redux-saga/effects';

// Instruments
import { types } from '../bus/types';

// Workers
import { fetchEntity } from './fetchEntity';

const tasks = [];

export function* watchFetchContent() {
    while (true) {
        const action = yield take(types.FETCH_CONTENT);

        const config = yield fork(fetchEntity, action, 'Content');

        tasks.push(config);
    }
}

export function* runEffects() {
    yield all([ watchFetchContent() ]);
}
