// Types
export const types = {
    FETCH_CONTENT:      'FETCH_CONTENT',
    FILL_CONTENT:       'FILL_CONTENT',
    SET_IS_FETCHING:    'SET_IS_FETCHING',
    SET_GAME_SLUG:      'SET_GAME_SLUG',
    SET_IS_API:         'SET_IS_API',
    SET_CURRENT_LANG:   'SET_CURRENT_LANG',
    SET_404:            'SET_404',
    SET_IS_SINGLE:      'SET_IS_SINGLE',
    SET_COMMENT_FORM:   'SET_COMMENT_FORM',
    SET_COMMENT_ACCESS: 'SET_COMMENT_ACCESS',
    SET_USER_DATA:      'SET_USER_DATA',
    SET_IS_LOGGED:      'SET_IS_LOGGED',
    SET_SHOW_MODAL:     'SET_SHOW_MODAL',
    SET_IS_MOBILE:      'SET_IS_MOBILE',
    SET_IS_TABLET:      'SET_IS_TABLET',
    SET_FEATURED:       'SET_FEATURED',
    SET_IS_DARK:        'SET_IS_DARK',
    FILL_SOCKET:        'FILL_SOCKET',
    FILL_CS_SOCKET:     'FILL_CS_SOCKET',
    SET_USED_IDS:       'SET_USED_IDS',
    SET_BANNERS:        'SET_BANNERS',
    SET_IS_PREMIUM:     'SET_IS_PREMIUM',
};
