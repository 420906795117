import { DOMAINS} from '../config';
import Cookies from 'js-cookie';

const rSymb = /[^a-zа-я0-9\/]/i; // eslint-disable-next-line
const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,4}$/i; // eslint-disable-next-line
export const isEmail = (value) => {
    return rEmail.test(value);
};

export const isValidName = (min = 0, max = 100, value) => {
    return !!(value && value.length >= min && value.length <= max);
};

export const goTo = (url) => {
    window.open(url, '_blank');
};

export const createToken = () => {
    return '5615e1a54fbaf51aebe2c22beee49c30';
};

export const virtualPage = (data) => {
    if (data && data.vp !== '' && data.vp_position !== '') {
        const parent = data.parent ? data.parent : 'bks';
        const pageView = `/${parent}/${data.vp}/${data.vp_position}`.toLowerCase();
        window.dataLayer = window.dataLayer || [];
        const _dataLayer = { event: `virt-view-${parent}`, [ `page-${parent}` ]: pageView };
        window.dataLayer.push(_dataLayer);
    }
};

export const removeFromLocalStorage = (storage) => {
    localStorage.removeItem(storage);
};
export const setArrayToLocalStorage = (storage, data) => {
    if (data) {
        const dataString = JSON.stringify(data);
        let result = btoa(encodeURIComponent(storage === 'section' ? dataString.slice(1, -1) : dataString));
        localStorage.setItem(storage, result);
    }
};
export const getArrayFromLocalStorage = (name) => {
    let values = null;
    try {
        const data = localStorage.getItem(name);
        const dataString  =  data ? decodeURIComponent(atob(data)) : null;
        if (dataString && dataString.length > 0) {
            const dataParsed = JSON.parse(name === 'section' ? '{' + dataString + '}' : dataString);
            if (dataParsed) {
                values = dataParsed;
            }
        }
    } catch (error) {
        //
    }

    return values;
};

export const accessComments = () => {
    let result = true;

    let storage = getArrayFromLocalStorage('section');
    if (storage && storage.token && storage.expires && new Date(storage.expires) > new Date() && typeof storage.comments !== 'undefined') {
        result = storage.comments;
    }

    return result;
};

export const getLanguageName = (code, currentLang) => {
    const lang = currentLang === 'ru' ? 'ru' : 'en';
    const item = DOMAINS.find((el)=>el.lang === code || el.code === code);

    return item && item.translate ? item.translate[ lang ] : '';
};

export const duplicateEncode = (word) => {
    const value = word.split('&nbsp;').join(' ');
    const arr = value.split('');
    let newArr = [];

    if (arr.length > 0) {
        newArr.push(arr[ 0 ]);
        for (let i = 1; i < arr.length; i++) {
            let push = true;
            if (rSymb.test(arr[ i ])) {
                if (arr[ i ] === arr[ i - 1 ]) {
                    push = false;
                }
                if (i > 2 && arr[ i - 1 ] + arr[ i ] === arr[ i - 3 ] + arr[ i - 2 ]) {
                    push = false;
                }
            }
            if (push) {
                newArr.push(arr[ i ]);
            }
        }
    }

    return newArr.join('');
};

export const getLength = (text) => {
    const result = text.split(' ').join('');

    return result.length;
};

export const getUserData = () => {
    let result = null;

    let storage = getArrayFromLocalStorage('section');
    if (storage && storage.token && storage.expires && new Date(storage.expires) > new Date()) {
        result = storage;
    }

    return result;
};

export const getEGWDomain = (currentLang) => {
    let subdomain = currentLang === 'en' ? '' : currentLang + '.';
    subdomain = currentLang === 'ru' ? 'rus.' : subdomain;

    const EGWdomain = 'https://' + subdomain + 'egamersworld.com';

    return EGWdomain;
};

export const isLive = (el) => {
    const now = new Date().toISOString();
    let live = !!(el && el.start_date && el.start_date < now);
    if (el && typeof el.isLive !== 'undefined') {
        live = el.isLive;
    }

    return live;
};

export const setUsedIdsList = (ids) => {
    Cookies.set('usedids', JSON.stringify(ids));
};

export const getUsedIdsList = () => {
    const data = Cookies.get('usedids');
    const list = data ? JSON.parse(data) : [];

    return list;
};

function randomInteger(min, max) {
    let rand = Math.random() * (max - min + 1);
    rand = Math.round(min - 0.5 + rand);

    return rand;
}

export const getBanner = (list) => {
    let result = null;
    if (list && list.length > 0) {
        result = list[ 0 ];
    }
    if (list && list.length > 1) {
        const rnd = randomInteger(1, 100);
        let el = list.length - 1;
        let selected_banner = {};
        let prev = 0;
        list.forEach(function(banner, k) {
            if (banner.percent) {
                const c = banner.percent + prev;
                if (c >= rnd && prev <= rnd) {
                    el = k;
                }
                prev = c;
            }
        });
        if (el < list.length) {
            selected_banner = list[ el ];
        }
        if (!selected_banner._id) {
            selected_banner = list;
        }
        result = selected_banner;
    }

    return result;
};
