// Core
import { put, call, apply, cancelled } from 'redux-saga/effects';

// Instruments
import { apiActions } from '../bus/actions';
import { api } from '../Api';

export function* fetchEntity(action, entityType) {
    try {
        yield put(apiActions.setIsFetching(true));
        const response = yield call(api[ `fetch${entityType}` ], action.payload);
        const content = yield apply(response, response.json);
        yield put(apiActions[ `fill${entityType}` ](content));
        yield put(apiActions.setIsFetching(false));
    } catch (error) {
        console.log('→ error', error);
    } finally {
        if (yield cancelled()) {
            console.log('→ cancelled!', action.type);
        }
    }
}
