import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { socketInit } from './Api/socket';
import { store } from './init';
import './theme/global.css';
const Main = lazy(() => import('./components/Main'));
const tagManagerArgs = {
    gtmId: 'GTM-577MR9F',
};
TagManager.initialize(tagManagerArgs);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store = { store }>
        <Suspense fallback>
            <Main />
        </Suspense>
    </Provider>,
);
socketInit(store);
