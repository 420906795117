// eslint-disable-line
export const book = Object.freeze({
    home:        '/',
    game:        '/:gameSlug/main',
    list:        '/:gameSlug/list-news',
    tag:         '/tag/:tagSlug',
    tagGame:     '/:gameSlug/news/tag/:tagSlug',
    single:      '/:gameSlug/news/:slug',
    singleNum:   '/:gameSlug/news/:newsNumber/:slug',
    aboutUs:     '/about-us',
    contacts:    '/contacts',
    partnership: '/partnership',
    terms:       '/terms',
    register:    '/register',
    reset:       '/reset',
    resetToken:  '/reset/:token',
    account:     '/user/:userSlug',
    accountEdit: '/user/:userSlug/edit',
    search:      '/search',
    searchGame:  '/:gameSlug/search',
    pagespeed:   '/pagespeed',
    steam:       '/auth/steam/authenticate',
    events:      '/schedule',
    gameEvents:  '/:gameSlug/schedule',
    singleEvent: '/schedule/:slug',
});
