// Core
import { all } from 'redux-saga/effects';

// Instruments
import { runEffects } from '../saga';

export function* rootSaga() {
    try {
        yield all([ runEffects() ]);
    } catch (error) {
        console.log('→ error caught', error);
    }
}
