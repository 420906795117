// Instruments
import { types } from './types';

const initialState = {
    isNotFound:    false,
    isFetching:    true,
    isApi:         false,
    isSingle:      false,
    isPremium:     false,
    content:       {},
    seo:           {},
    gameSlug:      null,
    currentLang:   'en',
    commentForm:   '',
    commentAccess: true,
    userData:      {},
    isLogged:      false,
    showModal:     false,
    isMobile:      false,
    isTablet:      false,
    isFeatured:    false,
    isDarkPage:    false,
    socket:        {},
    csSocket:      {},
    usedIds:       [],
    banners:       {},
};

export const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.payload,
            };
        }
        case types.SET_IS_API: {
            return {
                ...state,
                isApi: action.payload,
            };
        }
        case types.SET_IS_PREMIUM: {
            return {
                ...state,
                isPremium: action.payload,
            };
        }
        case types.FILL_CONTENT: {
            return {
                ...state,
                content: action.payload,
                seo:     action.payload.seo ? action.payload.seo : {},
            };
        }
        case types.SET_GAME_SLUG: {
            return {
                ...state,
                gameSlug: action.payload ? action.payload : null,
            };
        }
        case types.SET_CURRENT_LANG: {
            return {
                ...state,
                currentLang: action.payload,
            };
        }
        case types.SET_404: {
            return {
                ...state,
                isNotFound: action.payload,
            };
        }
        case types.SET_IS_SINGLE: {
            return {
                ...state,
                isSingle: action.payload,
            };
        }
        case types.SET_COMMENT_FORM: {
            return {
                ...state,
                commentForm: action.payload,
            };
        }
        case types.SET_COMMENT_ACCESS: {
            return {
                ...state,
                commentAccess: action.payload,
            };
        }
        case types.SET_USER_DATA: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case types.SET_IS_LOGGED: {
            return {
                ...state,
                isLogged: action.payload,
            };
        }
        case types.SET_SHOW_MODAL: {
            return {
                ...state,
                showModal: action.payload,
            };
        }
        case types.SET_IS_MOBILE: {
            return {
                ...state,
                isMobile: action.payload,
            };
        }
        case types.SET_IS_TABLET: {
            return {
                ...state,
                isTablet: action.payload,
            };
        }
        case types.SET_FEATURED: {
            return {
                ...state,
                isFeatured: action.payload,
            };
        }
        case types.SET_IS_DARK: {
            return {
                ...state,
                isDarkPage: action.payload,
            };
        }
        case types.FILL_SOCKET: {
            return {
                ...state,
                socket: action.payload,
            };
        }
        case types.FILL_CS_SOCKET: {
            return {
                ...state,
                csSocket: action.payload,
            };
        }
        case types.SET_USED_IDS: {
            return {
                ...state,
                usedIds: action.payload,
            };
        }
        case types.SET_BANNERS: {
            return {
                ...state,
                banners: action.payload,
            };
        }
        default:
            return state;
    }
};
