// Types
import { types } from './types';

export const apiActions = {
    fetchContent: (data) => ({
        type:    types.FETCH_CONTENT,
        payload: data,
    }),
    fillContent: (data) => ({
        type:    types.FILL_CONTENT,
        payload: data,
    }),
    setIsFetching: (state) => ({
        type:    types.SET_IS_FETCHING,
        payload: state,
    }),
    setGameSlug: (state) => ({
        type:    types.SET_GAME_SLUG,
        payload: state,
    }),
    setIsApi: (state) => ({
        type:    types.SET_IS_API,
        payload: state,
    }),
    setCurrentLang: (state) => ({
        type:    types.SET_CURRENT_LANG,
        payload: state,
    }),
    setNotFound: (state) => ({
        type:    types.SET_404,
        payload: state,
    }),
    setIsSingle: (state) => ({
        type:    types.SET_IS_SINGLE,
        payload: state,
    }),
    setCommentForm: (state) => ({
        type:    types.SET_COMMENT_FORM,
        payload: state,
    }),
    setCommentAccess: (state) => ({
        type:    types.SET_COMMENT_ACCESS,
        payload: state,
    }),
    setUserData: (state) => ({
        type:    types.SET_USER_DATA,
        payload: state,
    }),
    setIsLogged: (state) => ({
        type:    types.SET_IS_LOGGED,
        payload: state,
    }),
    setShowModal: (state) => ({
        type:    types.SET_SHOW_MODAL,
        payload: state,
    }),
    setIsPremium: (state) => ({
        type:    types.SET_IS_PREMIUM,
        payload: state,
    }),
    setIsMobile: (state) => ({
        type:    types.SET_IS_MOBILE,
        payload: state,
    }),
    setIsTablet: (state) => ({
        type:    types.SET_IS_TABLET,
        payload: state,
    }),
    setIsFeatured: (state) => ({
        type:    types.SET_FEATURED,
        payload: state,
    }),
    setIsDarkPage: (state) => ({
        type:    types.SET_IS_DARK,
        payload: state,
    }),
    fillSocket: (data) => ({
        type:    types.FILL_SOCKET,
        payload: data,
    }),
    fillCsSocket: (data) => ({
        type:    types.FILL_CS_SOCKET,
        payload: data,
    }),
    setUsedIds: (data) => ({
        type:    types.SET_USED_IDS,
        payload: data,
    }),
    setBanners: (data) => ({
        type:    types.SET_BANNERS,
        payload: data,
    }),
};
