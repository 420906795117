// eslint-disable-line
const isWiki = window.location.hostname && window.location.hostname.indexOf('lol') !== -1;
const hn = isWiki ? 'egw.lol' : 'egw.news';

const port = process.env.NODE_ENV === 'development' ? 'http' : 'https';
const domain = process.env.NODE_ENV === 'development' ? 'egw.test' : hn;

export const isDev = process.env.NODE_ENV === 'development';
export const SITE_URI = 'https://egw.news';
export const EGW_URI = 'https://egamersworld.com';
export const IMG_URI = SITE_URI;

export const PAGES = [
    {
        name: 'info',
        path: '/about-us',
        slug: 'about-us',
    },
    {
        name: 'contacts',
        path: '/contacts',
        slug: 'contacts',
    },
    {
        name: 'adv',
        path: '/partnership',
        slug: 'partnership',
    },
];

export const RUBRICS = [
    {
        gameSlug: 'esports',
        name:     'E-sports',
        short:    'E-sports',
        isRubric: true,
    },
    {
        gameSlug: 'gaming',
        name:     'Gaming',
        short:    'Gaming',
        isRubric: true,
    },
    {
        gameSlug: 'crypto',
        name:     'Crypto',
        short:    'Crypto',
    },
];

export const GAMES = [
    {
        gameSlug: 'counterstrike',
        name:     'CS:GO',
        short:    'CS:GO',
        isTop:    true,
    },
    {
        gameSlug: 'dota2',
        name:     'Dota 2',
        short:    'Dota 2',
        isTop:    true,
    },
    {
        gameSlug: 'lol',
        name:     'League of Legends',
        short:    'LOL',
        isTop:    true,
    },
    {
        gameSlug: 'valorant',
        name:     'VALORANT',
        short:    'VALORANT',
        isTop:    true,
    },
    {
        gameSlug: 'rainbowsix',
        name:     'Rainbow Six',
        short:    'Rainbow Six',
    },
    {
        gameSlug: 'rocketleague',
        name:     'Rocket League',
        short:    'Rocket League',
    },
    {
        gameSlug: 'overwatch',
        name:     'Overwatch',
        short:    'Overwatch',
    },
    {
        gameSlug: 'fifa',
        name:     'FIFA',
        short:    'FIFA',
    },
    {
        gameSlug: 'pubg',
        name:     'PUBG',
        short:    'PUBG',
    },
    {
        gameSlug: 'hearthstone',
        name:     'Hearthstone',
        short:    'Hearthstone',
    },
    {
        gameSlug: 'halo',
        name:     'Halo',
        short:    'Halo',
    },
    {
        gameSlug: 'callofduty',
        name:     'Call of Duty',
        short:    'Call of Duty',
    },
];

export const GAMES2 = [
    {
        gameSlug: 'wildrift',
        name:     'Wild Rift',
        short:    'Wild Rift',
        isOld:    true,
    },
    {
        gameSlug: 'heroesofthestorm',
        name:     'Heroes Of The Storm',
        short:    'HOTS',
        isOld:    true,
    },
    {
        gameSlug: 'artifact',
        name:     'Artifact',
        short:    'Artifact',
        isOld:    true,
    },
];

export const GAMESLIST = [ ...GAMES, ...GAMES2, ...RUBRICS ];

export const OLDGAMES = [ 'artifact', 'heroesofthestorm', 'wildrift' ];

export const DOMAINS = [
    {
        domain:    port + '://' + domain,
        lang:      'en',
        code:      'en_GB',
        text:      'English',
        translate: {
            en: 'English',
            ru: 'Английский',
        },
    },
    {
        domain:    port + '://ua.' + domain,
        lang:      'ua',
        code:      'ua',
        text:      'Українська',
        translate: {
            en: 'Ukrainian',
            ru: 'Українська',
        },
    },
    {
        domain:    port + '://rus.' + domain,
        lang:      'ru',
        code:      'ru',
        text:      'Русский',
        translate: {
            en: 'Russian',
            ru: 'Русский',
        },
    },
    {
        domain:    port + '://de.' + domain,
        lang:      'de',
        code:      'de',
        text:      'Deutsch',
        translate: {
            en: 'German',
            ru: 'Немецкий',
        },
    },
    {
        domain:    port + '://pt.' + domain,
        lang:      'pt',
        code:      'pt',
        text:      'Português',
        translate: {
            en: 'Portuguese',
            ru: 'Португальский',
        },
    },
    {
        domain:    port + '://es.' + domain,
        lang:      'es',
        code:      'es',
        text:      'Español',
        translate: {
            en: 'Spanish',
            ru: 'Испанский',
        },
    },
    {
        domain:    port + '://pl.' + domain,
        lang:      'pl',
        code:      'pl',
        text:      'Polski',
        translate: {
            en: 'Polish',
            ru: 'Польский',
        },
    },
    {
        domain:    port + '://fr.' + domain,
        lang:      'fr',
        code:      'fr',
        text:      'Français',
        translate: {
            en: 'French',
            ru: 'Французский',
        },
    },
    {
        domain:    port + '://tr.' + domain,
        lang:      'tr',
        code:      'tr',
        text:      'Türk',
        translate: {
            en: 'Turkish',
            ru: 'Турецкий',
        },
    },
    {
        domain:    port + '://fi.' + domain,
        lang:      'fi',
        code:      'fi',
        text:      'Suomeksi',
        translate: {
            en: 'Finnish',
            ru: 'Финский',
        },
    },
    {
        domain:    port + '://da.' + domain,
        lang:      'da',
        code:      'da',
        text:      'Dansk',
        translate: {
            en: 'Danish',
            ru: 'Датский',
        },
    },
    {
        domain:    port + '://no.' + domain,
        lang:      'no',
        code:      'no',
        text:      'Norsk',
        translate: {
            en: 'Norwegian',
            ru: 'Норвежский',
        },
    },
    {
        domain:    port + '://sv.' + domain,
        lang:      'sv',
        code:      'sv',
        text:      'Svenska',
        translate: {
            en: 'Swedish',
            ru: 'Шведский',
        },
    },
];

export const SWITCHER = [
    {
        domain:    port + '://' + domain,
        lang:      'en',
        code:      'en_GB',
        text:      'English',
        translate: {
            en: 'English',
            ru: 'Английский',
        },
    },
    {
        domain:    port + '://ua.' + domain,
        lang:      'ua',
        code:      'ua',
        text:      'Українська',
        translate: {
            en: 'Ukrainian',
            ru: 'Українська',
        },
    },
];

export const SOCIAL = [
    {
        cl:   'tw',
        link: '/V1r-Cy9NO',
        game: 'All News',
    },
    {
        cl:   'fb',
        link: '/EktOjOh9u',
        game: 'CS:GO',
    },
    {
        cl:   'tw',
        link: '/Nk2hj-25u',
        game: 'CS:GO',
    },
    {
        cl:   'fb',
        link: '/4JX5qO39d',
        game: 'Dota 2',
    },
    {
        cl:   'tw',
        link: '/EJ34ou3q-',
        game: 'Dota 2',
    },
    {
        cl:   'fb',
        link: '/NkQr0y5Nu',
        game: 'E-sports',
    },
    {
        cl:   'fb',
        link: '/NkQr0y5Nu',
        game: 'Games',
    },
    {
        cl:   'in',
        link: '/NJ1oYFtL6',
        game: 'Instagram',
    },
];

export const SOCIAL_RU = [
    {
        cl:   'tw',
        link: '/NJeD9dh5d',
        game: 'CS:GO',
    },
    {
        cl:   'tg',
        link: '/4yDb9d29u',
        game: 'CS:GO',
    },
    {
        cl:   'ti',
        link: '/V1rlCR1xp',
        game: 'CS:GO',
    },
    {
        cl:   'yt',
        link: '/NJQvYdiL9',
        game: 'CS:GO',
    },
    {
        cl:   'fb',
        link: '/EJrWG7jAw',
        game: 'Новости',
    },
    {
        cl:   'tw',
        link: '/4JUXG7jAP',
        game: 'Новости',
    },
    {
        cl:   'tw',
        link: '/V1vJ-dhcd',
        game: 'Dota 2',
    },
    {
        cl:   'tg',
        link: '/EJhvDdhqu',
        game: 'Dota 2',
    },
    {
        cl:   'in',
        link: '/NJ1oYFtL6',
        game: 'Instagram',
    },
];
