// Instruments
import { ROOT_URL as ROOT_URI, SEARCH_URI } from './config';
import { createToken } from '../instruments';
export const api = new class Api {
    fetchContent({ url, currentLang, search }) {
        let query = [ 'lang=' + currentLang ];
        if (search) {
            query.push('search=' + search);
        }
        if (window.location.search) {
            query.push(window.location.search.replace('?', ''));
        }
        if (window.location.hash) {
            query.push('game=' + window.location.hash.replace('#', ''));
        }
        const  uri = `${ROOT_URI}${url}${ query.length > 0 ? '?' + query.join('&')  : '' }`;

        return fetch(
            uri,
            {
                headers: {
                    'X-CustomHeader': createToken(uri, ROOT_URI),
                },
            },
        );
    }

    getData = async (url) => {
        const uri = `${ROOT_URI}${url}`;
        const response = await fetch(
            uri,
            {
                method:  'GET',
                headers: {
                    'X-CustomHeader': createToken(url, ROOT_URI),
                },
            },
        );
        const result = await response.json();

        return result;
    }

    postData = async (data, type) => {
        let url = `${ROOT_URI}${type}`;
        const response = await fetch(
            url,
            {
                method:      'POST', // *GET, POST, PUT, DELETE, etc.
                mode:        'cors', // no-cors, cors, *same-origin
                cache:       'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers:     {
                    'X-CustomHeader': createToken(url, ROOT_URI),
                    Accept:           'application/json',
                    'Content-Type':   'application/json',
                    Origin:           window.location.hostname,
                    //'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data),
            },
        );
        const result = await response.json();

        return result;
    }

    getSuggest = async ({ query, currentLang }) => {
        const url = `${SEARCH_URI}/news_${currentLang}/_search?pretty`;
        const body = {
            suggest: {
                'song-suggest': {
                    prefix:     query,
                    completion: {
                        field:           'suggest',
                        skip_duplicates: true,
                        size:            10,
                    },
                },
            },
        };

        const response = await fetch(
            url,
            {
                method:      'POST', // *GET, POST, PUT, DELETE, etc.
                mode:        'cors', // no-cors, cors, *same-origin
                cache:       'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers:     {
                    Accept:         'application/json',
                    'Content-Type': 'application/json',
                    //'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(body),
            },
        );

        const result = await response.json();

        if (result) {
            return result;
        }

        return null;
    }

    getExternalData = async (url, headers = {}) => {
        const response = await fetch(
            url,
            {
                method:  'GET', // *GET, POST, PUT, DELETE, etc.
                headers: headers,
            },
        );
        const result = await response.json();

        return result;
    };
}();
