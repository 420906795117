// eslint-disable-line
import openSocket from 'socket.io-client';
import { SOCKET_URI } from './config';
import { apiActions } from '../bus/actions';
const  socket = openSocket(SOCKET_URI, {
    transports: [ 'websocket', 'xhr-polling' ],
    origins:    '*:*',
});

function socketInit(store) {
    socket.on('connect', () => {
        socket.on('getSocketData', (data) => {
            store.dispatch(apiActions.fillSocket(data));
        });
        socket.on('cs', (data) => {
            store.dispatch(apiActions.fillCsSocket(data));
        });
        socket.on('getSocketScores', (data) => {
            store.dispatch(apiActions.fillSocket(data));
        });
    });
    socket.on('disconnect', () => {
        console.log('disconnect');
    });
}

export { socketInit };
